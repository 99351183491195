<template>
  <div class="home">
    <div class="home_inner" id="target" :style="{'width':winWidth+'px'}">
      <div class="top_red" :style="{'width':winWidth+'px'}">
        <div class="top_red_inner">
          使用喜藩APP播放课程将会有更好的体验
          <div class="black_button" @click="goxifan()">下载喜藩</div>
        </div>
      </div>
      <div class="top_img_div">
        <van-swipe :loop="false" v-if="notPlayVideo">
          <van-swipe-item>
            <van-image :src="course_cover" fit="cover" :width="winWidth" :height="winWidth" />
          </van-swipe-item>
        </van-swipe>
        <video
          v-else
          :poster="course_cover"
          :style="{'width':winWidth+'px','height':winWidth+'px'}"
          :src="video_attr"
          controls="controls"
          controlslist="nodownload"
        ></video>
      </div>
      <div class="home_more_inner">
        <div class="price_div">
          <div class="first_price">
            <div class="yellow_price">{{price}}</div>
            <div class="line_price" v-if="original_price!=null">¥{{original_price}}</div>
          </div>
          <div class="star_and_share">
            <!-- <div class="star_inner">
            <van-image :src="starImg" fit="cover" class="" />
            <div>{{save_num}}</div>
          </div>
          <div class="star_inner">
            <van-image :src="shareImg" fit="cover" class="" />
            </div>-->
          </div>
        </div>
        <div class="title_div">标题：{{title}}</div>
        <div class="number_div">
          <div class="number_common">
            <img src="../icon/eye_course.png" class="eye_img" alt />
            <div>{{totalView}}人看过</div>
          </div>
          <div class="number_common">
            <img :src="peopleImg" class="people_img" alt />
            <div>{{totalSale}}人参与</div>
          </div>
          <div class="number_common">
            <img src="../icon/course_time.png" class="time_img" alt />
            <div>{{totalMin}}分钟</div>
          </div>
        </div>
        <div class="little_title_div" v-show="inventories.length>0">课程目录</div>
        <div class="video_list" v-show="inventories.length>0">
          <div
            :class="index==inventories.length-1?'video_slot final_video':'video_slot'"
            v-for="(item,index) in inventories"
            :key="index"
            @click="playVideo(item.lesson_videos,item.free,isBuy)"
            v-show="inventories.length>0"
          >
            <div class="first_video_slot">
              <div class="index_slot">{{index+1}}</div>
              <div>{{item.lesson_name}}</div>
            </div>
            <div class="second_video_slot">
              <div>{{item.size}}分钟</div>
              <img v-if="item.free || isBuy" src="../icon/free_player.png" alt />
              <img v-else class="lock_class" src="../icon/video_lock.png" alt />
            </div>
          </div>
          <div v-if="inventories.length<1" style="padding:10px;text-align:center;font-size:15px">暂无课程</div>
        </div>
        <div class="black_slot"></div>
        <div class="gray_div">作者</div>
        <div class="writer_div">
          <div class="icon_div">
            <van-image :src="user_info.display_icon" fit="cover" class />
            <div>{{user_info.display_name}}</div>
            <div class="description_div">{{user_info.person_description}}</div>
          </div>
        </div>
        <div class="little_title_div" v-show="course_short && course_short.classShort!=''">摘要</div>
        <div class="common_div">{{course_short.classShort}}</div>
        <div class="little_title_div" v-show="course_short && course_short.forWho!=''">适合人群</div>
        <div class="common_div">{{course_short.forWho}}</div>
        <div class="little_title_div"  v-show="course_short && course_short.learnTarget!=''">学习目标</div>
        <div class="common_div">{{course_short.learnTarget}}</div>
        <div class="little_title_div" v-show="course_short && course_short.classLong!=''">详细介绍</div>
        <div class="common_div" style="white-space: pre-line;">{{course_short.classLong}}</div>
        <div class="img_list">
          <div class="img_slot" v-for="(item,index) in photoList" :key="index">
            <van-image :src="item"></van-image>
          </div>
        </div>
        <div class="little_title_div">温馨提示</div>
        <div class="common_div">
          <div>1: 付费视频课程，付费后永久免费。</div>
          <div>2: 课程为虚拟内容服务，购买成功后不可退款。</div>
          <!-- <div>3: 对课程有任何问题：加微信群0000000。</div> -->
          <div>3: 如有平台产品或技术问题：加微信xifanstylika</div>
        </div>
        <div v-if="relevantCourse.length!=0">
          <div class="black_slot"></div>
          <div class="gray_div">相关课程</div>
          <div class="other_div">
            <div
              class="other_slot"
              v-for="(item,index) in relevantCourse"
              :key="index"
              @click="goOtherCourse(item.id)"
            >
              <div class="other_left">
                <van-image :src="item.photo_main" fit="cover"></van-image>
              </div>
              <div class="other_right">
                <div class="right_first">{{item.name}}</div>
                <div class="right_second">{{item.user_info.display_name}}</div>
                <div class="right_third">{{item.user_info.person_description}}</div>
                <div class="right_fouth">
                  <div class="fouth_left">
                    <img src="../icon/eye_course.png" fit="cover" class />
                    {{item.views_count}}加入学习
                  </div>
                  <div class="fouth_price">{{item.price=='0.00'?'免费课程':'¥'+item.price}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height:90px"></div>
      </div>
      <div class="go_it_outer" :style="{'width':winWidth+'px'}">
        <div class="go_it" @click="isBuy?'':go_order()">{{isBuy?'已购买':'立即购买'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      shareImg: "http://qiniupc.stylika.online/upload_pic_1608881029316_h=500",
      starImg: "http://qiniupc.stylika.online/upload_pic_1608881122495_h=500",
      peopleImg: "http://qiniu.stylika.online/81620375717932h=500",
      winWidth: 0,
      id: "",
      course_cover: "",
      photoList: [],
      photoKey: ["photo_1", "photo_2", "photo_3", "photo_4", "photo_5"],
      height: 800,
      price: "0.00",
      original_price:'',
      title: "",
      totalView: 0,
      totalSale: 0,
      totalMin: 0,
      inventories: [],
      user_info: {
        icon: "",
        description: ""
      },
      course_short: {},
      relevantCourse: [],
      relevant_next: null,
      videoPath: null,
      isShow: true,
      url: "../../home/other/index?id=",
      isReachBottom: true,
      isBuy: false,
      isFree: false,
      isPresviewVideo: true,
      relevantGoods_next: null,
      relevantGoods: [],
      isReachRight: false,
      save_num: 0,
      other_pro: [],
      notPlayVideo: true,
      video_attr: "http://qiniupc.stylika.online/upload_video_1604480182928",
      isWXBrowser: false
    };
  },
  async mounted() {
    this.winWidth = document.body.clientWidth;
    if (this.winWidth > 800) {
      this.winWidth = 600;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getCourseInfo();
      this.getRelevantCourse();
    } else {
      this.$router.push("/404");
    }
    window.localStorage.removeItem("WXPaymentData");
    window.localStorage.removeItem("CourseData");
    window.localStorage.removeItem("WXCode");

    this.isWXBrowser = await this.$store.dispatch("isWXBrowser");

    if (this.isWXBrowser) {
      this.getWXConfigData();
    }
  },
  methods: {
    getWXConfigData() {
      this.$iHttp
        .post("/api/lesson/purchase/get_sign/", {
          url: location.href.split("#")[0]
        })
        .then(res => {
          this.$wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: "wx467928844a5c4ee0", // 必填，公众号的唯一标识
            timestamp: res.data.data.sign.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.sign.noncestr, // 必填，生成签名的随机串
            signature: res.data.data.sign.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
            success(res) {
              console.log(res);
            },
            fail(err) {
              console.log(err);
            }
          });
          let that = this;
          this.$wx.ready(function() {
            //设置消息分享参数
            that.setShareMessageParameters();
            //设置朋友圈分享参数
            that.setShareTimelineParameters();
          });
        });
    },
    setShareMessageParameters() {
      this.$wx.updateAppMessageShareData({
        title: this.title, // 分享标题
        desc: this.course_short.classShort, // 分享描述
        link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.course_cover, // 分享图标
        success: function() {
          // 设置成功
          console.log("SetShareMessageParameters success!");
        }
      });
    },
    setShareTimelineParameters() {
      this.$wx.updateTimelineShareData({
        title: this.title, // 分享标题
        link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.course_cover, // 分享图标
        success: function() {
          // 设置成功
          console.log("SetShareTimelineParameters success!");
        }
      });
    },
    goxifan() {
      window.location.href = "http://www.stylika.online/";
    },
    getCourseInfo() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中..."
      });
      let min = 0;
      this.$iHttp
        .get("/api/lesson/info/?id=" + this.id)
        .then(res => {
          this.photoList = [];
          this.photoKey.map(item => {
            if (res.data.data[item]) {
              // res.data.data.product_info[item] = res.data.data.product_info[item] +
              // 	'?imageView2/q/50&imageslim'
              // let img = {
              // 	image: res.data.data.product_info[item]
              // }
              this.photoList.push(res.data.data[item]);
            }
          });
          this.course_cover = res.data.data.photo_main;
          this.isBuy = res.data.data.purchase_status;
          this.isSave = res.data.data.save_status;
          this.price = res.data.data.price;
          this.original_price=res.data.data.original_price
          this.isFree = res.data.data.price == "0.00";
          this.title = res.data.data.course_name;
          this.save_num = res.data.data.save_count;
          this.totalSale = res.data.data.order_count
            ? res.data.data.order_count
            : 0;
          this.totalView = res.data.data.views_count;
          if (Number(this.totalView) >= 1000) {
            this.totalView = (Number(this.totalView) / 1000).toFixed(1) + "k";
          } else if (Number(this.totalView) >= 10000) {
            this.totalView = (Number(this.totalView) / 10000).toFixed(1) + "w";
          }
          this.inventories = res.data.data.lessons;
          this.user_info = res.data.data.instructor_profile;
          this.course_short = JSON.parse(res.data.data.description);
          this.inventories.map(item => {
            min += Number(item.size);
          });
          this.totalMin = min;
          // if (this.inventories[0].lesson_videos == null) {
          //   this.isPresviewVideo = false;
          // } else {
          //   this.videoPath = this.inventories[0].lesson_videos;
          // }
          // this.setShareMessageParameters()
          // this.setShareTimelineParameters()
        })
        .finally(fin => {
          this.$toast.clear();
        });
    },
    getRelevantCourse(type) {
      if (type == "load") {
        this.isReachBottom = true;
        this.$iHttp
          .post("/api/product/related_lessons/", {
            id: this.id
          })
          .then(res => {
            this.isReachBottom = false;
            this.relevant_next = res.data.data.next;
            res.data.data.results.map(item => {
              if (Number(item.views_count) >= 1000) {
                item.views_count =
                  (Number(item.views_count) / 1000).toFixed(1) + "k";
              } else if (Number(item.views_count) >= 10000) {
                item.views_count =
                  (Number(item.views_count) / 10000).toFixed(1) + "w";
              }
              this.relevantCourse.push(item);
            });
          })
          .catch(err => {
            this.isReachBottom = false;
          });
      } else {
        this.$iHttp
          .post("/api/product/related_lessons/", {
            id: this.id
          })
          .then(res => {
            if (res.data.errcode != -1) {
              this.relevant_next = res.data.data.next;
              this.relevantCourse = res.data.data.results;
              this.relevantCourse.map(item => {
                if (Number(item.views_count) >= 1000) {
                  item.views_count =
                    (Number(item.views_count) / 1000).toFixed(1) + "k";
                } else if (Number(item.views_count) >= 10000) {
                  item.views_count =
                    (Number(item.views_count) / 10000).toFixed(1) + "w";
                }
                if (item.user_info.person_description.length > 35) {
                  item.user_info.person_description =
                    item.user_info.person_description.substr(0, 35) + "..";
                }
              });
            }
          });
      }
    },
    playVideo(url, free, isBuy) {
      if (free || isBuy) {
        this.video_attr = url;
        this.notPlayVideo = false;
      } else {
        this.$toast("请先加入学习");
      }
    },
    goOtherCourse(id) {
      window.scrollTo(0, 0);
      this.id = id;
      this.notPlayVideo = true;
      this.getCourseInfo();
      this.getRelevantCourse();
    },
    go_order() {
      let storeList = {
        store_name: this.user_info.display_name,
        store_icon: this.user_info.display_icon,
        store_id: this.user_info.user_id
      };
      let courseData = {
        store_id: this.user_info.id,
        quantity: 1,
        product_id: this.id,
        product_name: this.title,
        is_pre_sale: false,
        pic: this.course_cover,
        price: this.price,
        des: this.course_short.classShort,
        storeList: storeList
      };
      window.localStorage.setItem("CourseData", JSON.stringify(courseData));
      this.$router.push({
        path: "/order",
        query: {
          id: this.id
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: center;
  .home_inner {
    max-width: 800px;
    .top_red {
      position: fixed;
      top: 15px;
      z-index: 1;
      display: flex;
      justify-content: center;
      .top_red_inner {
        width: 10rem;
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: -webkit-linear-gradient(left, #e88787, #ea2929);
        border-radius: 5px;
        color: white;
        font-size: 12px;
        .black_button {
          background-color: black;
          width: 2.5rem;
          height: 30px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .home_more_inner {
      width: 88%;
      margin: auto;
      .price_div {
        display: flex;
        justify-content: space-between;
        margin: 0.35rem 0;
        .first_price {
          display: flex;
          align-items: flex-end;

          .yellow_price {
            color: #ff9802;
            font-size: 25px;
            margin-right: 0.3rem;
          }
          .line_price {
            text-decoration: line-through;
            font-size: 18px;
            color: #878787;
          }
        }
        .star_and_share {
          display: flex;
          .star_inner {
            display: flex;
            font-size: 16px;
            margin: 0 0.3rem;
            .van-image {
              width: 0.5rem;
              height: 0.5rem;
              margin: 0 0.1rem;
            }
          }
        }
      }
      .title_div {
        font-size: 0.65rem;
      }
      .number_div {
        display: flex;
        margin: 0.35rem 0;
        font-size: 13px;
        color: #c0c0c0;
        .number_common {
          display: flex;
          align-items: center;
          margin-right: 0.8rem;
          img {
            margin-right: 0.15rem;
          }
          .eye_img {
            width: 0.5rem;
            height: 0.3rem;
          }
          .people_img {
            width: 0.4rem;
            height: 0.4rem;
          }
          .time_img {
            width: 0.4rem;
            height: 0.46rem;
          }
        }
      }
      .little_title_div {
        margin: 0.3rem 0;
      }
      .video_list {
        background-color: #f4f4f4;
        border-radius: 5px;
        width: 105%;
        margin: auto;
        position: relative;
        right: 2.5%;
        .video_slot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 1.3rem;
          border-bottom: 1px solid #e4e4e4;
          .first_video_slot {
            display: flex;
            font-size: 16px;
            .index_slot {
              margin: 0 0.4rem;
              width: 0.3rem;
            }
          }
          .second_video_slot {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #929292;
            img {
              width: 0.3rem;
              height: 0.3rem;
              margin: 0 0.4rem;
            }
            .lock_class {
              height: 0.4rem;
            }
          }
        }
        .final_video {
          border: none;
        }
      }
      .black_slot {
        width: 0.55rem;
        height: 0.1rem;
        background-color: black;
        margin: 0.4rem 0 0.15rem;
      }
      .gray_div {
        color: #858585;
      }
      .writer_div {
        .icon_div {
          display: flex;
          flex-direction: column;
          align-items: center;
          .van-image {
            width: 1.6rem;
            height: 1.6rem;
            margin: 0.2rem 0;
          }
          .description_div {
            color: #b1b1b1;
            margin: 0.2rem 0 0.4rem;
            font-size: 14px;
          }
        }
      }
      .common_div {
        font-size: 14px;
        color: #949494;
        margin-bottom: 0.9rem;
        word-wrap: break-word;
				white-space: pre-line;
      }
      .img_list {
        .img_slot {
          margin: 0.2rem 0;
        }
      }
      .other_div {
        .other_slot {
          display: flex;
          border: 1px solid #d5d5d5;
          margin: 0.3rem 0;
          padding: 0.2rem;
          border-radius: 5px;
          .other_left {
            .van-image {
              width: 3.25rem;
              height: 3.25rem;
            }
            margin-right: 0.2rem;
          }
          .other_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .right_second {
              font-size: 14px;
              // margin:0.05rem 0;
            }
            .right_third {
              font-size: 12px;
              color: #c0c0c0;
              // margin-bottom: 0.5rem;
            }
            .right_fouth {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              width: 5.5rem;
              .fouth_left {
                img {
                  width: 0.45rem;
                  height: 0.3rem;
                }
                color: #c0c0c0;
                font-size: 12px;
              }
              .fouth_price {
                color: #ff9802;
              }
            }
          }
        }
      }
    }
    .go_it_outer {
      position: fixed;
      background-color: white;
      border-top: 1px solid #f5f5f5;
      z-index: 1;
      bottom: 0;
      height: 70px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .go_it {
        background-color: black;
        color: white;
        height: 1rem;
        width: 3rem;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        margin-right: 22px;
      }
    }
  }
}
</style>
